import toDate from "date-fns/toDate"

export const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
]

export const getUTC = (date = new Date()) => {
  const d = toDate(date)
  const nextDay = d.getUTCDay() === 7 ? 0 : d.getUTCDay() + 1
  const hour = d.getUTCHours() + 1
  const day = hour === 24 ? days[nextDay] : days[d.getUTCDay()]
  const minute = d.getUTCMinutes()
  const second = d.getUTCSeconds()

  return {
    day,
    hour,
    minute,
    second,
  }
}

export const formatSeconds = seconds =>
  new Date(seconds * 1000)
    .toISOString()
    .substr(14, 8)
    .replace(".", ":")
