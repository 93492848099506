import { useEffect } from "react"
import { useColorMode } from "@xstyled/styled-components"

import theme from "../../../utils/theme"
import { getUTC } from "../../../utils/date"

const ColorModeSetter = () => {
  const [colorMode, setColorMode] = useColorMode()

  useEffect(() => {
    const setDarkMode = () => {
      // if (document.body.classList.contains("themeModeChanged")) return
      const { start, end } = theme.lightMode
      const utc = getUTC()

      if (utc.hour >= start && utc.hour <= end) {
        setColorMode("default")
      } else {
        setColorMode("dark")
      }
    }

    setInterval(() => {
      setDarkMode()
    }, 60000)
    setDarkMode()
  }, [setColorMode])

  return false
}

export default ColorModeSetter
