import React from "react"
import PropTypes from "prop-types"
import styled, {
  ThemeProvider,
  ColorModeProvider,
} from "@xstyled/styled-components"
import "modern-normalize/modern-normalize.css"

import ColorModeSetter from "./atoms/ColorModeSetter"

import theme from "../utils/theme"

import "./base.css"
import "./input-range.css"

const Container = styled.div`
  min-height: 100vh;
  background-color: light;
  color: dark;
  transition: 0.3s;
`

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ColorModeProvider>
        <ColorModeSetter />
        <Container>{children}</Container>
      </ColorModeProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
