const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  useColorSchemeMediaQuery: false,
  colors: {
    dark: "#000",
    light: "#fff",
    modes: {
      dark: {
        dark: "#fff",
        light: "#000",
      },
    },
  },
  fonts: ["'Playfair+Display', sans-serif"],
  fontSizes: [
    10,
    12,
    14,
    16,
    18,
    20,
    22,
    24,
    26,
    28,
    30,
    34,
    38,
    40,
    44,
    48,
    50,
    54,
    58,
    60,
  ],
  space: [
    0,
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100,
    110,
    120,
    130,
    140,
    150,
    160,
    170,
    180,
    190,
    200,
  ],
  lightMode: {
    start: 8,
    end: 17,
  },
  zIndices: {
    header: 500,
    over: 9999,
  },
  idleTime: "5e3",
}

export default theme
