import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

const metaDesc =
  "A free to view curatorial space that will exist online to celebrate creative works for a moment in time as an alternative to irl."
const metaAuthor = "@compulsory"

function SEO({ description, lang, meta, title }) {
  const metaDescription = description || metaDesc

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `og:url`,
          content: `https://compulsory.gallery`,
        },
        {
          name: `og:image`,
          content: `https://compulsory.gallery/og-image.jpg`,
        },
        {
          name: `og:image:type`,
          content: `image/jpeg`,
        },
        {
          name: `og:image:width`,
          content: `1080`,
        },
        {
          name: `og:image:height`,
          content: `655`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
